import React from 'react';
import ShortTopLoader from '../../components/ShortTopLoader';

const Page = React.lazy(() => import('./Page'));

const CookiesPolicy = ({ location }) => {
  return (
    <React.Suspense fallback={<ShortTopLoader />}>
      <Page location={location} />
    </React.Suspense>
  );
};

export default CookiesPolicy;
